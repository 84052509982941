import React, { Fragment } from 'react';
import {Event} from '../Event';
import './Schedule.css';

// interface Props {
//     season: string
// }
// const dateOptions = {
//     hourCycle: 'h12',
//     // dayPeriod: "short",
//     month: "short",
//     weekday: "short",
//     day: "numeric",
//     hour: "numeric",
//     minute: "numeric",
//     timeZoneName: "short"
// };

export const Schedule = (props) => {
    
    const renderSchedule = () => {
        if (props.games.length > 0) {
            return (
                <Fragment>
                    <div className="Schedule md:w-8/12">
                        {
                            props.games.map((game, index) => {
                                return <Event key={index} game={game}/>
                            })
                        }
                    </div>
                    {/* <table>
                        <thead>
                            <tr>
                                <td>Date</td>
                                <td>Opponent</td>
                                <td>Location</td>
                                <td>Channel</td>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            props.games.map((game, index) => {
                                return (
                                    <tr key={index} className="">
                                        <td>{new Date(game.date).toLocaleDateString('en-US', dateOptions)}</td>
                                        <td>{game.opponent}</td>
                                        <td>{game.location}</td>
                                        <td>{game.channel ? game.channel : "Not aired"}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table> */}
                </Fragment>
            );
        } else if (props.loading) {
            return (
                <Fragment>
                    <div className="Schedule">
                        Loading...
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div className="Schedule">
                        No data to show.
                    </div>
                </Fragment>
            );
        }
    }
    
    return (
        <div id="scheduleContainer">
            {renderSchedule()}
        </div>
    );
}