import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import { Menu } from '../Menu/Menu';
import { Display } from '../Display/Display';
import { ScheduleHeader } from '../ScheduleHeader/ScheduleHeader';

import './Header.css';

export const Header = (props) => {
    let location = useLocation();
    const [open, setOpen] = useState(false);
    const node = useRef(); 
    
    useEffect(() => {
        const listener = event => {
          if (!node.current || node.current.contains(event.target)) {
            return;
          }
          setOpen(false);
        };
        document.addEventListener('mousedown', listener);
        return () => {
          document.removeEventListener('mousedown', listener);
        };
      },
      [node, setOpen],
      );
  
    const toggleOpen = () => {
      setOpen(!open);
    }

    return (
        <header>
            <div id="titlebar" ref={node}>
                <Menu
                    open={open}
                    setOpen={toggleOpen}
                />
                <BurgerMenu
                    open={open}
                    setOpen={toggleOpen}
                />
                <div className="title">
                    {props.year} Kentucky Basketball Schedule
                </div>
            </div>
            <Display />
            {location.pathname !== "/" && 
                <ScheduleHeader />
            }
        </header>
    );
}