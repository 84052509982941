import React, { useEffect, useRef } from 'react';
import 'web-animations-js';

import './Display.css';

export const Display = (props) => {
    const textRotation = [
        "For God so loved the world, that he gave his only begotten Son,",
        "... that whosoever believeth in him should not perish, but have everlasting life. John 3:16",
        "For by grace are ye saved through faith; and that not of yourselves: it is the gift of God:",
        "... Not of works, lest any man should boast. Ephesians 2:8-9"
    ];
    let rotationIndex = useRef(0);
    let rotationLengthInSeconds = 8.0;
    let fading = useRef();

    const fadeKeyframes = [
        { opacity: 0.0, visibility: "hidden" },
        { opacity: 1.0, visibility: "visible" },
        { opacity: 0.0, visibility: "hidden" },
    ];
    const fadeTiming = {
        id: "fade",
        delay: 10,
        direction: "reverse",
        duration: rotationLengthInSeconds * 1000,
        easing: "linear",
        fill: "none",
        iterations: 1,
    };

    useEffect(() => {
        if (!fading.current){
            transitionText();
        }
    });

    const transitionText = () => {
        fading.current = document.getElementById(`displayText_${rotationIndex.current}`).animate(
            fadeKeyframes,
            fadeTiming,
        );
        rotationIndex.current = (rotationIndex.current + 1) % textRotation.length;
        fading.current.onfinish = function () {
            transitionText();
        };
    }

    const pauseTransition = () => {
        fading.current.pause();
    }

    const playTransition = () => {
        fading.current.play();
    }

    return (
        <div 
            className="display shadow-lg py-1 md:py-4"
            onMouseEnter={() => pauseTransition()}
            onMouseLeave={() => playTransition()}
        >
            <div className="displayBox mx-auto">
                {
                    textRotation.map((text, index) => {
                        return (
                            <div key={index} id={`displayText_${index}`} className="DisplayText invisible">
                                {text}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}