import './ScheduleHeader.css';

export const ScheduleHeader = (props) => {
    return (
        <div className="flex flex-col justify-center items-center text-center bg-opacity-100">
            <div className="inline-grid md:w-8/12">
                <div className="ScheduleHeader shadow-md h-16 min-w-full">
                    <div className="EventHeader w-28 md:w-48">
                        <label>Date</label>
                    </div>
                    <div className="EventHeader w-28 md:w-44">
                        <label>Opponent</label>
                    </div>
                    <div className="EventHeader w-28 md:w-44">
                        <label>Location</label>
                    </div>
                    <div className="EventHeader w-28 md:w-44">
                        <label>Channel</label>
                    </div>
                </div>
            </div>
        </div>
    );
}