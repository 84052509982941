import './BurgerMenu.css';

export const BurgerMenu = (props) => {
    return (
        <button
            className={props.open ? "BurgerMenu BurgerOpen" : "BurgerMenu BurgerCollapsed"}
            open={props.open}
            onClick={() => props.setOpen()}
        >
            <div/>
            <div/>
            <div/>
        </button>
    )
}